<template>
  <v-theme-provider root>
    <v-app id="inspire">
      <div :style="$vuetify.theme.dark?'':'background-color: rgba(0,0,0,0.06)'">
        <span v-if="ready">
          <v-banner
            v-if="reloadBanner"
            single-line
            color="error"
            sticky
          >
            <v-icon
              slot="icon"
              color="warning"
              size="36"
            >
              mdi-wifi-strength-alert-outline
            </v-icon>
            The system was updated. Please reload your page now to get the latest version.

            <template v-slot:actions>
              <v-btn
                text
                @click="reloadPage"
              >
                Reload Now
              </v-btn>
            </template>
          </v-banner>
          <v-navigation-drawer
              class="hideOnPagePrint"
              v-if="this.isLoggedIn"
              permanent
              app
              :mini-variant="true&&(this.getGlobalValue('VEC_ADMIN_SIDEBAR_MINI')=='true'||true)"
          >
            <v-list dense>
              <template v-for="item in this.menuItems">
                <v-tooltip :key="item.id" right open-on-hover open-delay="400">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-row
                          v-if="item.heading"
                          :key="item.heading"
                      >
                        <v-col cols="6">
                          <v-subheader v-if="item.heading" style='color: white!important;'>
                            {{ item.heading }}
                          </v-subheader>
                        </v-col>
                        <v-col
                            cols="6"
                            class="text-center"
                        >
                          <a
                              href="#!"
                              class="body-2 black--text"
                          >EDIT</a>
                        </v-col>
                      </v-row>
                      <v-list-group
                          v-else-if="item.children"
                          :key="item.text"
                          v-model="item.model"
                          :prepend-icon="item.model ? item.icon : item['icon-alt']"
                          append-icon=""
                      >
                        <template v-slot:activator>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ item.text }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                        <v-list-item
                            v-for="(child, i) in item.children"
                            :key="i"
                            link
                            :to="item.path"
                        >
                          <v-list-item-action v-if="child.icon">
                            <v-icon>{{ child.icon }}</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ child.text }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-group>
                      <v-list-item
                          v-else
                          :key="item.text"
                          link
                          :to="item.path"
                      >
                        <v-list-item-action>
                          <v-icon :style="'color: '+(getGlobalValue('VEC_ADMIN_SIDENAV_ICON_COLOR')||'#1F5490')+'!important;'">{{ item.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.text }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </span>
                  </template>
                  <span>{{item.text}}</span>
                </v-tooltip>
              </template>
            </v-list>
          </v-navigation-drawer>
          <v-navigation-drawer
              v-if="this.getGlobalValue('VEC_ADMIN_NOTIFICATION_BAR')=='true'"
              class="hideOnPagePrint"
              v-model="notificationSidebar.isOpen"
              right
              temporary
              app
          >
            <v-list dense>
              <span class="d-flex flex-column align-center px-3">
                <h1>Notifications</h1>
                <!-- <v-btn class="ml-3" style="margin-top: -5px;" @click="closeNotifications" color="warning" x-small fab><v-icon>mdi-close</v-icon></v-btn> -->
              </span>
              <span v-if="notificationSidebar.notifs && notificationSidebar.notifs.length > 0">
                <span v-for="(item, index) in notificationSidebar.notifs" :key="index" class="pa-3 d-flex flex-column mb-3" style="background-color: rgba(0,0,0,0.25);">
                  <div v-if="item.Notification.actions && item.Notification.actions.click && item.Notification.actions.click.redirect" @click="$router.push({path: item.Notification.actions.click.redirect})" style="cursor: pointer;">
                    {{ index+1 }}.
                    {{ item.Notification.name }}
                    <b>{{ utils.formatDate(item.Notification.createdAt, 'withTime') }}</b>
                  </div>
                  <div v-else>
                    {{ item.Notification.name }}
                    <b>{{ utils.formatDate(item.Notification.createdAt, 'withTime') }}</b>
                  </div>
                </span>
              </span>
            </v-list>
          </v-navigation-drawer>
          <v-main>
            <div v-if="this.isLoggedIn && this.getUser" :style="'width: 100%; height: '+(this.getGlobalValue('VEC_ADMIN_TOPNAV_HEIGHT')||'60px')+'; background-color: '+(this.getGlobalValue('VEC_ADMIN_TOPNAV_BG_COLOR')||'#2970C0')+'; color:'+(this.getGlobalValue('VEC_ADMIN_TOPNAV_FONT_COLOR')||'white!important')+';padding: 10px; position: absolute;'" class="d-flex flex-row justify-space-between hideOnPagePrint">
              <img class="ml-3" :src="this.getEndpoint+'/images/logo.png'" :height="(this.getGlobalValue('VEC_ADMIN_TOPNAV_LOGO_HEIGHT')||'50px')" alt="">
              <strong style="margin-top: 7px;">{{getGlobalValue('ADMIN_APPLICATION_NAME')}}</strong>
              <span style="margin-top: 7px;">{{this.getGreeting()}}</span>
              <span style="margin-top: 7px;" >{{getGlobalValue('ADMIN_APPLICATION_BANNER_MESSAGE') }}</span>
              <span class="d-flex flex-row align-center">
                <span v-if="this.getCurrentRegister">
                  <v-btn fab small class="mr-3" @click="openCashMovementDialog"><v-icon>mdi-cash-register</v-icon></v-btn>
                </span>
                <span v-if="this.getGlobalValue('VEC_GLOBAL_CREATE_ADHOC_PRODUCT_ALLOWED') == 'true'">
                  <v-btn fab small class="mr-3" @click="openGlobalAdhocCreateProductDialog"><v-icon>mdi-plus</v-icon></v-btn>
                </span>
                <span>
                  <v-btn fab small class="mr-3" @click="openSearchDialog"><v-icon>mdi-magnify</v-icon></v-btn>
                </span>
                <span>
                  <span class="mr-3">F2 to chord</span>
                </span>
                <span v-if="isAllowed('user', 'changeMain')&&(this.getGlobalValue('VEC_INCLUDES_MULTIBRANCH')=='true')" class="d-flex flex-row">
                  <v-select
                      class="ignoreWhite"
                      outlined
                      dense
                      dark
                      style="margin-bottom: -20px; width: 200px;"
                      label="Current Branch"
                      :items="this.getAllowedBranches"
                      item-text="name"
                      item-value="id"
                      :value="this.getBranch"
                      @change="updateBranch($event)"
                      :loading="branchLoader"
                  />
                </span>
                <span v-else>
                  <span v-if="(this.getGlobalValue('VEC_INCLUDES_MULTIBRANCH')=='true')">Branch: {{this.lookupBranch(this.getUser.metadata.branchData.main)}}</span>
                </span>
                <span>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="ml-1" :color="isManaged?'white':'#bbb'" v-bind="attrs" v-on="on">mdi-sitemap</v-icon>
                    </template>
                    <span>This branch uses {{isManaged?'a ':'an un'}}managed inventory system.</span>
                  </v-tooltip>
                </span>
                <span>
                  <v-btn class="ml-3" style="margin-top: -5px;" @click="toggleVuetifyTheme()" :color="$vuetify.theme.dark?'purple':'info'" x-small fab>
                    <v-icon v-if="$vuetify.theme.dark">mdi-weather-night</v-icon>
                    <v-icon v-else>mdi-weather-sunny</v-icon>
                  </v-btn>
                  <v-btn class="ml-3" style="margin-top: -5px;" @click="logoutA" color="success" x-small fab><v-icon>mdi-close</v-icon></v-btn>
                </span>
                <span>
                    <v-btn v-if="this.getGlobalValue('VEC_ADMIN_NOTIFICATION_BAR')=='true'" class="ml-3" style="margin-top: -5px;" @click="openNotifications" color="info" x-small fab>
                      <v-badge v-if="notificationSidebar.badgeValue" dot color="error">
                        <v-icon>mdi-bell</v-icon>
                      </v-badge>
                      <v-icon v-else >mdi-bell</v-icon>
                    </v-btn>
                </span>
                <span v-if="this.getGlobalValue('VEC_INCLUDES_DISPLAYS')=='true'">
                    <v-btn class="ml-3" style="margin-top: -5px;" @click="openCfdSettings" color="danger" x-small fab>
                      <v-icon>mdi-cast</v-icon>
                    </v-btn>
                </span>
              </span>
            </div>
            <v-container
                class="fill-height adjustForPagePrint"
                style="justify-content: flex-start; align-items: flex-start; padding: 80px 30px 30px 30px;"
                v-if="(this.isLoggedIn && this.getUser) || (!this.isLoggedIn && !this.getUser)"
                fluid
            >
              <router-view :key="$route.path+getBranch+getUpdateCounter"/>
              <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
                {{ snackObj.text }}
                <template v-slot:action="{ attrs }">
                  <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
                </template>
              </v-snackbar>
            </v-container>

            <v-dialog v-model="cfdSettings.isOpen" width="500">
              <v-card>
                <v-card-title>CFD Settings</v-card-title>
                <v-card-text>
                  <v-text-field v-if="cfdSettings.data" hide-details class="mb-2" dense outlined label="This Device" v-model="cfdSettings.deviceCookie" disabled/>
                  <v-text-field v-if="cfdSettings.data" hide-details class="mb-2" dense outlined label="Device Description" v-model="cfdSettings.data.name" disabled/>
                  <v-text-field v-if="cfdSettings.data" hide-details class="mb-2" dense outlined label="Display ID" v-model="cfdSettings.data.serial" disabled/>
                  <v-text-field v-if="cfdSettings.data" hide-details class="mb-2" dense outlined label="Display Pair Code" v-model="cfdSettings.code"/>
                  <v-btn color="error" :loading="cfdSettings.unpairIsLoading" text @click="unpairDisplay">UNPAIR Display</v-btn>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="attemptPairDisplay" :loading="cfdSettings.pairIsLoading" :disabled="!cfdSettings.code">Pair</v-btn>
                  <v-btn color="primary" text @click="closeCfdSettings">Cancel</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="globalAdhocCreateProductDialog.isOpen" width="500">
              <v-card>
                <v-card-title>Create Product</v-card-title>
                <v-card-text>
                  <v-text-field hide-details class="mb-2" dense outlined label="Product Name" v-model="globalAdhocCreateProductDialog.newProductData.name"/>
                  <v-text-field hide-details class="mb-2" dense outlined label="Regular Price" v-model="globalAdhocCreateProductDialog.newProductData.regularPrice"/>
                  <v-text-field hide-details class="mb-2" dense outlined label="SKU" @change="trimBC(globalAdhocCreateProductDialog.newProductData)" v-model="globalAdhocCreateProductDialog.newProductData.sku"/>
                  <v-checkbox hide-details class="mb-2" dense label="Manage Stock?" v-model="globalAdhocCreateProductDialog.newProductData.manageStock"/>
                  <v-checkbox hide-details class="mb-2" dense v-if="getGlobalValue('showRequireSerialsOnGlobalProductCreate')==='true'" :label="`Require ${serialNaming?.plural}?`" v-model="globalAdhocCreateProductDialog.newProductData.metadata.requireSerials"/>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="success" :loading="globalAdhocCreateProductDialog.isLoading" @click="createNewAdhocProduct">Create</v-btn>
                  <v-btn color="info" @click="closeGlobalAdhocCreateProductDialog">Cancel</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="logoutDialog" width="500">
              <v-card>
                <v-card-title>Confirm Logout</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="primary"
                      text
                      @click="logoutB()"
                  >
                    Logout
                  </v-btn>
                  <v-btn
                      color="primary"
                      text
                      @click="logoutDialog = false"
                  >
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="productDialog.isOpen" width="500">
              <v-card>
                <v-card-title>Scanned Product</v-card-title>
                <v-card-text>
                  <v-progress-circular
                      indeterminate
                      color="green"
                      v-if="productDialog.isLoading"
                      style="margin-left: 10px;"
                  ></v-progress-circular>
                  <div class="d-flex flex-column justify-space-between" style="align-text: left; background-color: rgba(0,0,0,0.05); padding: 10px; margin-top: 6px; border-radius: 7px;">
                    <span class="d-flex flex-row justify-space-between">
                      <b style="text-align: left;">{{productDialog.data.Brand?`${productDialog.data.Brand.name} `:''}} {{productDialog.data.name}}</b>
                    </span>
                    <span class="d-flex flex-column align-start">
                      <span><b>SP: ${{productDialog.data.regularPrice}}</b>, <span v-if="isAllowed('product', 'viewCostPrice')">PP: ${{productDialog.data.salePrice}}</span></span>
                      <span>ID: {{productDialog.data.id}}, SKU:{{productDialog.data.sku}}</span>
                      <span><b>QTY: {{productDialog.data.ProductLocationJoins?.find(x => x.userBranch===true)?productDialog.data.ProductLocationJoins.find(x => x.userBranch===true).available:0}}</b><span v-if="getGlobalValue('showBranchQuantities')==='true'">[{{productDialog.data.ProductLocationJoins?.reduce((total, curr) => total+parseInt(curr.available), 0)}}]</span></span>
                    </span>
                  </div>
                  <!-- {{productDialog.data}} -->
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="closeProductDialog" >Close</v-btn>
                  <v-btn color="info" text @click="goToProduct">View Product</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
  
            <v-dialog v-model="chordingDialog.isOpen" width="600">
              <v-card>
                <v-card-title class="d-flex flex-row justify-space-between">
                  <h1>Let's go!</h1>
                  <span>
                    <span v-for="(key,index) in chordingDialog.chord" :key="index">
                        <v-chip x-small dense>{{key.toUpperCase()}}</v-chip>
                    </span>
                  </span>
                </v-card-title>
                <v-card-text class="d-flex flex-column">
                  <span class="d-flex flex-row">
                    <span class="d-flex flex-row" v-if="chordingDialog.chord.length<1">
                      <div class="d-flex flex-column  justify-center px-2">
                        <v-chip class="mr-2" small color="info">N</v-chip>
                        <b>New Order</b>
                      </div>
                      <div class="d-flex flex-column px-2">
                        <v-chip class="mr-2" small color="info">S</v-chip>
                        <b>Search</b>
                      </div>
                      <div class="d-flex flex-column px-2">
                        <v-chip class="mr-2" small color="info">H</v-chip>
                        <b>Home</b>
                      </div>
                      <div class="d-flex flex-column px-2">
                        <v-chip class="mr-2" small color="info">O</v-chip>
                        <b>Orders</b>
                      </div>
                      <!-- <div class="d-flex flex-column px-2">
                        <v-chip class="mr-2" small color="info">C</v-chip>
                        <b>Customers</b>
                      </div> -->
                    </span>
                    <span v-else>
                      <span class="d-flex flex-row" v-if="chordingDialog.chord[0] == 'o'">
                        <div class="d-flex flex-column  justify-center px-2">
                          <v-chip class="mr-2" small color="success">O</v-chip>
                          <b>Orders</b>
                        </div>
                        <div v-if="chordingDialog.chord[1]!='v'" class="d-flex flex-column  justify-center px-2">
                          <v-chip class="mr-2" small color="info">N</v-chip>
                          <b>New</b>
                        </div>
                        <div v-if="chordingDialog.chord.length<2||chordingDialog.chord[1]=='v'" class="d-flex flex-column  justify-center px-2">
                          <v-chip class="mr-2" small :color="chordingDialog.chord[1]=='v'?'success':'info'">V</v-chip>
                          <b>View</b>
                        </div>
                        <div v-if="chordingDialog.chord[1]!='v'" class="d-flex flex-column  justify-center px-2">
                          <v-chip class="mr-2" small color="info">L</v-chip>
                          <b>List</b>
                        </div>
                        <span v-if="chordingDialog.chord[1]=='v'">Enter the order ID and press ENTER</span>
                      </span>
                      <span class="d-flex flex-row align-center" v-if="chordingDialog.chord[0] == 's'">
                        <div class="d-flex flex-column  justify-center px-2">
                          <v-chip class="mr-2" small color="success">S</v-chip>
                          <b>Search</b>
                        </div>
                        <div v-if="chordingDialog.chord.length<2||chordingDialog.chord[1]=='p'" class="d-flex flex-column  justify-center px-2">
                          <v-chip class="mr-2" small :color="chordingDialog.chord[1]=='p'?'success':'info'">P</v-chip>
                          <b>Products</b>
                        </div>
                        <div v-if="chordingDialog.chord.length<2||chordingDialog.chord[1]=='c'" class="d-flex flex-column  justify-center px-2">
                          <v-chip class="mr-2" small :color="chordingDialog.chord[1]=='c'?'success':'info'">C</v-chip>
                          <b>Customers</b>
                        </div>
                        <span v-if="chordingDialog.chord[1] != null">Enter the search term.</span>
                      </span>
                    </span>
                  </span>
                  <span v-if="search.results.length>0">
                    <span class="d-flex flex-row align-center mb-1">
                      <h3>Results ({{search.results.length}})</h3>
                    </span>
                    <span v-if="search.type==='Products'">
                      <span v-for="(product, i) in search.results" :key="i">
                        <v-card style="cursor: pointer;" outlined class="mb-1 pa-1" @click="$router.push(`/products/view/${product.id}`)">
                          <v-chip color="primary"><b>{{product.ProductLocationJoins.find(x => x.userBranch===true)?product.ProductLocationJoins.find(x => x.userBranch===true).available:0}}</b><span v-if="getGlobalValue('showBranchQuantities')==='true'">[{{product.ProductLocationJoins.reduce((total, curr) => total+parseInt(curr.available), 0)}}]</span></v-chip>
                          <span class="ml-2">ID:{{product.id}} | </span>
                          <span class="ml-2" style="font-size: 18px;"><b>{{product.Brand?'['+product.Brand.name+']':''}} {{product.name}}</b></span>
                          <span v-if="product.sku"> | {{product.sku}}</span>
                          <span style="float: right;">{{utils.formatCurrency(product.regularPrice)}}</span>
                        </v-card>
                      </span>
                    </span>
                    <span v-if="search.type==='Customers'">
                      <span v-for="(customer, i) in search.results" :key="i">
                        <v-card style="cursor: pointer;" outlined class="mb-1 pa-1" @click="$router.push(`/customers/view/${customer.id}`)">
                          <span style="border: 1px solid black; border-radius: 0;" class="pa-1">{{customer.id}}</span>
                          <span class="ml-2" style="font-size: 18px;"><b>{{customer.name}}</b></span>
                          <span v-if="customer.email"> | {{customer.email}}</span>
                          <span style="float: right;">{{customer.phone}}</span>
                        </v-card>
                      </span>
                    </span>
                  </span>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <span>Press <v-chip small dense>X</v-chip> to clear, <v-chip small dense>Backspace</v-chip> to go back one, or <v-chip small dense>F2</v-chip> to cancel.</span>
                </v-card-actions>
              </v-card>
            </v-dialog>
  
            <v-dialog v-model="search.dialog" width="900" height="600">
              <v-card style="overflow-y: scroll;" max-height="900">
                <v-card-title></v-card-title>
                <v-form :disabled="search.loading">
                  <v-card-text>
                    <span class="d-flex flex-row">
                      <v-text-field outlined label="Search everything" @change="querySearch" v-model="search.value" style="border-top-right-radius: 0; border-bottom-right-radius: 0;" :loading="search.loading"/>
                      <v-select outlined label="Type" @change="querySearch" :items="search.types" style="width: 50px; border-top-left-radius: 0; border-bottom-left-radius: 0;" v-model="search.type"/>
                    </span>
                    <span v-if="search.results.length>0">
                      <span class="d-flex flex-row align-center mb-1">
                        <h3>Results ({{search.results.length}})</h3>
                        <v-btn @click="clearSearch" class="ml-2" fab x-small color="error"><v-icon>mdi-close</v-icon></v-btn>
                      </span>
                      <span v-if="search.type==='Products'">
                        <span v-for="(product, i) in search.results" :key="i">
                          <v-card style="cursor: pointer;" outlined class="mb-1 pa-1" @click="$router.push(`/products/view/${product.parent?product.parent:product.id}`)">
                            <v-chip color="primary"><b>{{product.ProductLocationJoins.find(x => x.locationId===getBranch)?product.ProductLocationJoins.find(x => x.locationId===getBranch).available:0}}</b><span v-if="getGlobalValue('showBranchQuantities')==='true'" class="ml-1">[{{product.ProductLocationJoins.reduce((total, curr) => total+parseInt(curr.available), 0)}}]</span></v-chip>
                            <span class="ml-2">ID:{{product.id}} | </span>
                            <span class="ml-2" style="font-size: 18px;"><b>{{product.Brand?'['+product.Brand.name+']':''}} {{product.name}}</b></span>
                            <span v-if="product.sku"> | {{product.sku}}</span>
                            <span v-if="product.type"> | {{product.type}}</span>
                            <span style="float: right;">{{utils.formatCurrency(product.regularPrice)}}</span>
                            <span v-if="getGlobalValue('VEC_INCLUDES_BLUETAGPRICE')==='true' && product.metadata && product.metadata.blueTagPrice" style="float: right;">BLUE TAG: {{utils.formatCurrency(product.metadata.blueTagPrice)}}</span>
                          </v-card>
                        </span>
                      </span>
                      <span v-if="search.type==='Customers'">
                        <span v-for="(customer, i) in search.results" :key="i">
                          <v-card style="cursor: pointer;" outlined class="mb-1 pa-1" @click="$router.push(`/customers/view/${customer.id}`)">
                            <span style="border: 1px solid black; border-radius: 0;" class="pa-1">{{customer.id}}</span>
                            <span class="ml-2" style="font-size: 18px;"><b>{{customer.name}}</b></span>
                            <span v-if="customer.email"> | {{customer.email}}</span>
                            <span style="float: right;">{{customer.phone}}</span>
                          </v-card>
                        </span>
                      </span>
                    </span>
                  </v-card-text>
                </v-form>
              </v-card>
            </v-dialog>
  
            <v-dialog v-model="cashMovementDialog.isOpen" width="600" height="600">
              <v-card>
                <v-card-title>
                  <span>Cash Register</span>
                  <span v-if="cashMovementDialog.data.type==='in'" class="ml-1">- Add Cash</span>
                  <span v-if="cashMovementDialog.data.type==='out'" class="ml-1">- Withdraw Cash</span>
                </v-card-title>
                <v-form :disabled="cashMovementDialog.isLoading">
                  <v-card-text>
                    <span class="d-flex flex-row justify-space-around" v-if="!cashMovementDialog.data.type">
                      <v-btn color="info" @click="cashMovementDialog.data.type='in'">Add Cash</v-btn>
                      <v-btn color="info" @click="cashMovementDialog.data.type='out'">Withdraw Cash</v-btn>
                    </span>
                    <span v-if="cashMovementDialog.data.type==='in'">
                      <v-text-field outlined label="Amount" type="number" v-model="cashMovementDialog.data.amount"/>
                      <v-text-field outlined label="Notes" v-model="cashMovementDialog.data.notes"/>
                      <span class="d-flex flex-row justify-end">
                        <v-btn :loading="cashMovementDialog.isLoading" @click="cashMovement" :disabled="cashMovementDialog.data.amount<=0" color="success" class="mr-2">Add Cash</v-btn>
                        <v-btn @click="cashMovementDialog.data.type=null" text color="error">Go Back</v-btn>
                      </span>
                    </span>
                    <span v-if="cashMovementDialog.data.type==='out'">
                      <v-text-field outlined label="Amount" type="number" v-model="cashMovementDialog.data.amount"/>
                      <v-text-field outlined label="Notes" v-model="cashMovementDialog.data.notes"/>
                      <span class="d-flex flex-row justify-end">
                        <v-btn :loading="cashMovementDialog.isLoading" @click="cashMovement" :disabled="cashMovementDialog.data.amount<=0" color="success" class="mr-2">Withdraw Cash</v-btn>
                        <v-btn @click="cashMovementDialog.data.type=null" text color="error">Go Back</v-btn>
                      </span>
                    </span>
                  </v-card-text>
                </v-form>
              </v-card>
            </v-dialog>
  
          </v-main>
        </span>
        <span v-else class="d-flex flex-row align-center justify-center">
          <span class="d-flex flex-column align-center">
            <img :src="this.getEndpoint+'/images/logo.png'" width="200px;">
            <span class="text-h4">System Loading</span>
            <span>
              <v-progress-circular
                  indeterminate
                  color="green"
              ></v-progress-circular>
            </span>
          </span>
        </span>
      </div>
    </v-app>
  </v-theme-provider>
</template>

<script>
  import { mapGetters, mapMutations } from "vuex"
  import scanSystem from "./plugins/scanSystem"
  import { io } from "socket.io-client"
  import axios from 'axios'
  import utils from "./plugins/helpers"
  export default{
    props: {
      source: String,
    },
    data: () => ({
      notifsEnabled: false,
      utils: utils,
      tempDeviceRegisterId: '32a',
      dialog: false,
      drawer: null,
      timeout: null,
      logoutDialog:false,
      ready: true,
      globalAdhocCreateProductDialog: {
        isOpen: false,
        isLoading: false,
        newProductData: {
          manageStock: true,
          metadata: {}
        }
      },
      search: {
        value: "",
        type: "Products",
        types: ["Products", "Customers"],
        dialog: false,
        loading: false,
        results: [],
      },

      snackObj: {
        state: false,
        color: '',
        text: ''
      },
      branchLoader: false,
      chordingDialog:{
        isOpen: false,
        chord: []
      },
      productDialog: {
        isOpen: false,
        isLoading: false,
        data: {},
      },

      cashMovementDialog: {
        isOpen: false,
        isLoading: false,
        data: {
          amount: 0,
          notes: '',
          type: null
        }
      },

      cfdSettings: {
        isOpen: false,
        pairIsLoading: false,
        unpairIsLoading: false,
        data: null,
        deviceCookie: "",
      },
      reloadBanner: false,
    }),
    watch: {
      $route (to, from){
        if(to.name==="order"){
          this.setScanBus({handler: 'order'})
          this.deactivateGlobalScanSense()
        }
        if(from.name==="order"&& to.name!=="order"){
          this.resetScanBus()
          this.activateGlobalScanSense()
        }
        if(to.name==="Return"){
          this.setScanBus({handler: "return"})
          this.deactivateGlobalScanSense()
        }
        if(from.name==="Return"&& to.name!=="Return"){
          this.resetScanBus()
        }
        if(to.name==="promotion"){
          this.setScanBus({handler: "promotion"})
          this.deactivateGlobalScanSense()
        }
        if(from.name==="promotion"&& to.name!=="promotion"){
          this.resetScanBus()
          this.activateGlobalScanSense()
        }
        if(to.name==="receivingReport"){
          console.log("TS DEBUG: RR")
          this.setScanBus({handler: "receivingReport"})
          this.deactivateGlobalScanSense()
        }
        if(from.name==="receivingReport"&& to.name!=="receivingReport"){
          console.log("TS DEBUG: RR BACK")
          this.resetScanBus()
          this.activateGlobalScanSense()
        }
        // if(to.name==="product"){
        //   this.setScanBus({handler: "product"})
        // }
        // if(from.name==="product"&& to.name!=="product"){
        //   this.resetScanBus()
        // }
      },
      activateScanSensePing(){
        window.removeEventListener('keydown', this.keydown)
        // this.loadProductCache()
        this.activateGlobalScanSense()
      },
      scanBus: {
        handler(){
          console.log("APP.VUE Watcher: ScanBus Changed")
          if(!this.scanBus.data || this.scanBus.data == "" || this.scanBus.data == undefined){
            console.log("Watcher detected that scanbus was reset. Terminating handoff to the reserving handler: globalHandler.")
            return
          }
          if(this.scanBus.handler == 'global'){
            this.globalHandler()
          }
        },
        deep: true
      }
    },
    async mounted(){
      try {
        this.ready = false;
        // very important: determine what is the origin
        let origin = window.location.origin;
        if(origin == process.env.VUE_APP_CLOUD_ORIGIN){
          this.setEndpoint(process.env.VUE_APP_CLOUD_ENDPOINT)
        }else if(origin == process.env.VUE_APP_PREM_ORIGIN){
          this.setEndpoint(process.env.VUE_APP_PREM_ENDPOINT)
        }else if(origin.includes(process.env.VUE_APP_LOCALHOST_ORIGIN)){
          this.setEndpoint(process.env.VUE_APP_LOCALHOST_ENDPOINT)
        }else if(origin.includes(process.env.VUE_APP_PUBLIC_ORIGIN)){
          this.setEndpoint(process.env.VUE_APP_PUBLIC_ENDPOINT)
        }else{
          this.setEndpoint(process.env.VUE_APP_EPHEMERAL_ENDPOINT)
        }
        console.log(`Origin: ${origin} | Endpoint Set: ${this.getEndpoint}`)

        /*
          envConstants are used with semi-dynamic
          values for certain application variables, where
          they are required before the user is authenticated
          and can request the GLOBALS from db.

        */
        let envConstants = {
          ADMIN_TITLE: process.env.VUE_APP_ADMIN_TITLE,
          ADMIN_LOGIN_PAGE_TITLE: process.env.VUE_APP_ADMIN_LOGIN_PAGE_TITLE,
        }

        document.querySelector('title').innerHTML = envConstants.ADMIN_TITLE
        this.setEnvConstants(envConstants)

        this.$vuetify.theme.dark = this.darkTheme
        
        let preLogins = await axios.get(`${this.getEndpoint}/auth/preAuthData`)
        if(preLogins && preLogins.data && preLogins.data.data){
          preLogins = preLogins.data.data
        }else {
          preLogins.allowDisplays = true
        }

        let cook = null;

        if(preLogins.allowDisplays){
          cook = document.cookie.match('(^|;)?' + "sourceId" + '=([^;]*)(;|$)')
          if(cook || cook === 0){
            cook = cook[2]
            this.cfdSettings.deviceCookie = cook
          }else{
            while(!cook && cook !== 0){
              cook = prompt("Enter device ID.")
              document.cookie = "sourceId = " + cook + "; expires = " + new Date(864000000000000)
            }
          }
        }

        if(this.isLoggedIn){
          console.log("ANyhing")
          this.setGLOBALS((await axios.get(`${this.getEndpoint}/api/globals`)).data.data)

          let branches = await axios.get(`${this.getEndpoint}/api/locations/branches`)
          if(branches.data.error) console.log("APP.JS❌ Location Data Not Fetched.")
          else this.setBranches(branches.data.data);

          this.setQuickList((await axios.get(`${this.getEndpoint}/api/quicklist`))?.data.data)

          let locations = await axios.get(`${this.getEndpoint}/api/locations`)
          if(locations.data.error) console.log("APP.JS❌ Location Data Not Fetched.")
          else this.setLocations(locations.data.data);

          if(!this.getUser){
            // let user = await axios.get(`${this.getEndpoint}/api/users/${this.getId}`) //⁉ WHY WAS THIS ROUTE PULLING ALL THESE THINGS AND WHY IS IT ONLY SLOW AF RN (16.05.2023)
            let user = await axios.get(`${this.getEndpoint}/api/users/self`)
            if(!user.data.error) this.setUser(user.data.data)
          }

          if(this.paymentMethods.length<1){
            let paymentMethods = await axios.get(`${this.getEndpoint}/api/paymentmethods/lite`)
            if(!paymentMethods.data.error) this.setPaymentMethods(paymentMethods.data.data)
          }

          if(this.paymentTypes.length<1){
            let paymentTypes = await axios.get(`${this.getEndpoint}/api/paymenttypes/lite`)
            if(!paymentTypes.data.error) this.setPaymentTypes(paymentTypes.data.data)
          }
          
          if(this.taxTypes.length<1){
            let taxTypes = await axios.get(`${this.getEndpoint}/api/taxtypes`)
            if(!taxTypes.data.error) this.setTaxTypes(taxTypes.data.data)
          }

          if(this.getPerms==null){
            let perms = await axios.get(`${this.getEndpoint}/getPerms`)
            if(!perms.data.error) this.setPerms(perms.data)
          }

          this.setManaged(!!this.getBranchData(this.getBranch)?.metadata.isManaged)
          console.log("APP SETTING USER CACHE")
          let userNames = await axios.get(`${this.getEndpoint}/api/users/userNames`)
          if(userNames.data.error) console.log("APP.JS❌ User Cache Data Not Fetched.")
          else this.setUserCache(userNames.data.data)

          this.initSockets(cook)

          let notifs = await axios.get(`${this.getEndpoint}/api/notifications/user/${this.getId}`)
          if(notifs.data.error) console.log("APP.JS❌ Notification Data Not Fetched.")
          else this.setNotifications(notifs.data.data)
          if (!("Notification" in window)) {
            alert("This browser does not support desktop notification");
          } else if (Notification.permission === "granted") {
            this.notifsEnabled = true
          } else if (Notification.permission !== "denied") {
            Notification.requestPermission().then((permission) => {
              if (permission === "granted") {
                this.notifsEnabled = true
              }
            });
          }

          if(this.getGlobalValue('registerModuleEnabled')==='true'){
            let reg = await axios.get(`${this.getEndpoint}/api/registers/verifyOpen/${this.getCurrentRegister}`)
            if(reg.data.error) console.log("APP.JS❌ Register Data Not Fetched.")
            this.updateCurrentRegister(reg.data.registerUUID)
          }

          if(this.getGlobalValue('replaceProductSerialsWithIMEI')){
            this.setSerialNaming({
              singular: "IMEI",
              singularLower: "IMEI",
              plural: "IMEIs",
              pluralLower: "IMEIs",
            })
          }

          this.$forceUpdate();

          //Barcode Daemon
          //Barcode and Shortcut Daemon
          if(this.scanBus.handler === 'global' )
            this.activateGlobalScanSense()
        }

        if(this.getCookie("reload")){
          this.reloadBanner = true
        }
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
        console.error("Could not load user permissions. Please refresh the page.")
      } finally {
        this.ready = true;
      }
    },
    computed: {
      ...mapGetters([
        'isLoggedIn',
        'getPerms',
        'globalQueryBcAllowed',
        'getEndpoint',
        'isAllowed',
        'getUser',
        'getId',
        'scanBus',
        'lookupUsername',
        'lookupBranch',
        'getAllowedBranches',
        'getBranch',
        'isManaged',
        'menuItems',
        'getBranchData',
        'paymentTypes',
        'taxTypes',
        'paymentMethods',
        'activateScanSensePing',
        'getGlobalValue',
        'getQuickList',
        'notificationSidebar',
        'getCurrentRegister',
        'getGlobalValue',
        'getUpdateCounter',
        'serialNaming',
        'darkTheme'
      ]),
      theme(){
        return (this.$vuetify.theme.dark) ? 'dark' : 'light'
      }
    },
    methods: {
      ...mapMutations([
        'logout',
        'setPerms',
        'setUser',
        'setEndpoint',
        'setUserCache',
        'lockGlobalQueryBc',
        'unlockGlobalQueryBc',
        'setScanBus',
        'resetScanBus',
        'setBranches',
        'setManaged',
        'setPaymentTypes',
        'setPaymentMethods',
        'setGLOBALS',
        'setLocations',
        'setEnvConstants',
        'setQuickList',
        'setNotifications',
        'openNotifications',
        'closeNotifications',
        'setTaxTypes',
        'updateCurrentRegister',
        'forceUpdateCounter',
        'setSerialNaming',
        'toggleTheme',
        'setNotificationBadge'
      ]),
      getCookie(name){
        return document.cookie.split(';').some(c => {
            return c.trim().startsWith(name + '=');
        });
      },
      getCookieValue(name){
        let x = document.cookie.split(';').find(c => {
            return c.trim().startsWith(name+'=')
        })
        if(x){
          x = x.split("=")[1]
        }
        return x
      },
      deleteCookie( name, path, domain ) {
        if( this.getCookie( name ) ) {
          document.cookie = name + "=" +
            ((path) ? ";path="+path:"")+
            ((domain)?";domain="+domain:"") +
            ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
        }
      },
      reloadPage(){
        console.log("Reload")
        this.deleteCookie("reload")
        location.reload()
      },
      async unpairDisplay(){
        try {
          this.cfdSettings.unpairIsLoading = true
          let res = await axios.post(`${this.getEndpoint}/api/devices/unpairDisplay/${this.cfdSettings.deviceCookie}`)
          if(res.data.error) throw res.data.error
          this.snack("Display unpaired.", "success")
        } catch (error) {
          console.log(error)
        } finally {
          this.cfdSettings.unpairIsLoading = false
        }
      },
      async attemptPairDisplay(){
        try {
          this.cfdSettings.pairIsLoading = true
          let res = await axios.post(`${this.getEndpoint}/api/devices/pairDisplay/${this.cfdSettings.deviceCookie}`, {code: this.cfdSettings.code})
          if(res.data.error) throw res.data.error
          this.closeCfdSettings()
        } catch (error) {
          console.log(error)
        } finally {
          this.cfdSettings.pairIsLoading = false
        }
      },
      async openCfdSettings(){
        try {
          let res = await axios.get(`${this.getEndpoint}/api/devices/${this.cfdSettings.deviceCookie}`)
          if(res.data.error) throw res.data.error
          this.cfdSettings.data = res.data.data.device
          this.cfdSettings.isOpen = true
          this.cfdSettings.pairIsLoading = false
          this.cfdSettings.unpairIsLoading = false
        } catch (error) {
          console.log(error)
        }
      },
      closeCfdSettings(){
        this.cfdSettings.isOpen = false
        this.cfdSettings.code = ""
      },
      trimBC(prod){
        if(!prod.sku) return
        prod.sku = prod.sku.replace(/\\\/\/\\=/,'')
      },
      sendNot(name, body){
        console.log("Failsafe for notification: ", name, body)
        new Notification(name, {
          body: body
        });
      },
      async toggleVuetifyTheme(){
        try {
          this.$vuetify.theme.dark = !this.$vuetify.theme.dark
          console.log(this.$vuetify.theme.dark)

          this.toggleTheme(this.$vuetify.theme.dark)
        } catch (error) {
          console.log(error)
        }
      },
      async createNewAdhocProduct(){
        try {
          this.globalAdhocCreateProductDialog.isLoading = true
          let prod = await axios.post(`${this.getEndpoint}/api/products`, this.globalAdhocCreateProductDialog.newProductData)
          if(prod.data.error) throw prod.data.error

          this.snack('Success! Product Created.')
          this.closeGlobalAdhocCreateProductDialog()

        } catch (error) {
          console.log(error)
          this.snack(error)
        } finally {
          this.globalAdhocCreateProductDialog.isLoading = false
        }
      },
      closeProductDialog(){
        this.productDialog = {
          isOpen: false,
          isLoading: false,
          data: {}
        }
      },
      openGlobalAdhocCreateProductDialog(){
        this.globalAdhocCreateProductDialog.isOpen = true
      },
      closeGlobalAdhocCreateProductDialog(){
        this.globalAdhocCreateProductDialog = {
          isOpen: false,
          isLoading: false,
          newProductData: {
            manageStock: true,
            metadata: {}
          }
        }
      },
      openCashMovementDialog(){
        this.cashMovementDialog.isOpen = true
      },
      closeCashMovementDialog(){
        this.cashMovementDialog = {
          isOpen: false,
          isLoading: false,
          state: null,
          data: {
            amount: 0,
            notes: '',
            type: null
          }
        }
      },
      async cashMovement(){
        try{
          this.cashMovementDialog.isLoading = true;

          if(!this.cashMovementDialog.data.type) throw "Cash Movement type not stated."
          if(!this.cashMovementDialog.data.amount || isNaN(this.cashMovementDialog.data.amount) || this.cashMovementDialog.data.amount<0) throw "Invalid Amount for Transaction"
          if(this.getGlobalValue('requireNotesForCashMovements')==='true' && !this.cashMovementDialog.data.notes?.trim()) throw "Notes are required to perform this transaction."

          if(!this.getCurrentRegister) throw "Error getting a valid register. Please refresh the application and try again."

          let res = await axios.post(`${this.getEndpoint}/api/registers/cashMovement/${this.getCurrentRegister}`, this.cashMovementDialog.data)
          if(res.data.error) throw res.data.error

          this.closeCashMovementDialog();

          this.snack("Transaction occurred successfully!", "success");
          if(this.$route.path==="/" || this.$route.path.startsWith('/registers')) this.forceUpdateCounter();
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally{
          this.cashMovementDialog.isLoading = false;
        }
      },
      initSockets(deviceId){
        const socket = io(this.getEndpoint);
        socket.on('connect', () => {
          console.log("Client WS Connected to server")
          socket.emit("userIdentify",this.getId)
          if(deviceId){
            socket.emit("sourceIdentify",deviceId)
          }
        })
        socket.on('ack', () => {
          console.log("ACK From server")
        })
        socket.on("notification", async (n)=>{
          console.log("WS Notification: ", n)
          this.setNotificationBadge(true)
          n = {Notification: n}
          this.notificationSidebar.notifs.unshift(n)
          if(this.notifsEnabled){
            let not = new Notification("Varion One", {
              icon: this.getEndpoint+'/images/logo.png',
              body: n.Notification.name,
            });
            console.log("NOT", not)
          }
        })
        socket.on("updateBanner", async (n)=>{
          console.log("WS Reload: ", n)
          document.cookie = "reload = " + true + "; expires = " + new Date(864000000000000)
          this.reloadBanner = true
        })
      },
      logoutA(){
        this.logoutDialog = true
      },
      logoutB(){
        console.log("Logout")
        this.logoutDialog = false
        this.$router.push({path: "/logout"})
      },
      activateGlobalScanSense(){
        console.log('APP.JS: 🌎✅ Global Scan Sense Active.')
        window.addEventListener('keydown', this.keydown)
        // window.addEventListener('keydown', (e)=>{
        //   console.log("GLOB",e)
        // })  
      },
      deactivateGlobalScanSense(){
        console.log('APP.JS: 🌎❌ Global Scan Sense Inactive.')
        window.removeEventListener('keydown', this.keydown)  
      },
      keydown(e){
        
        //experiment
        // console.log('APP:',e)
        // if(e.key==='F1'||e.key==='F2'||e.key==='F4'){
        //   e.preventDefault()
        //   console.log(e.key, " was pressed.")
        // }
        //end of experiment

        // let elem = document.querySelector('button:focus');
        // if(elem) {
        //   elem.blur();
        // }
        const CHORDKEY = 'F2'
        //formulates a scanstring and passes it to the scanbus
        if(this.getUser){ //only active when user is logged in

          //console.log('APP', e)
          this.cancelClearScanStringTimeout()
          this.clearScanStringTimeout()

          if(this.chordingDialog.isOpen){ // if we are in chording mode, suspend scanning operations until exited

            this.cancelClearScanStringTimeout()

            if(e.key===CHORDKEY){
              this.endShortcut()
            }else{
              this.handleChord(e)
            }

          }else{ //user is not in chording mode (could either be typing or scanning)
            
            if(e.key==="Enter"){ //if keypress is enter
              //determine if scanString is a barcode
              let isBarcode = false

              if(this.scanString?.length>5){  //a barcode will at least have a 5 char signature \//\= and at least 1 character. if this is not met, we can assume its not valid
                isBarcode = scanSystem.validateBarcode(this.scanString)
              }
              
              if(isBarcode){ // if it is a barcode 
                
                // push to scanbus 
                this.setScanBus({data: "x"})
                this.setScanBus({data: this.scanString})
              }else {

                console.log("sd")

              }
            }else{ // keypress is not enter
                
              if(e.key===CHORDKEY && (!this.scanString || this.scanString?.length == 0)){ // if  keyPress is CHORDKEY and the current scanString is empty ie a bc is not being streamed in
                
                //open chording Dialog
                this.cancelClearScanStringTimeout()
                this.chordingDialog.isOpen = true 
                //set scanbus for global chording
                this.setScanBus({handler:"globalChord", data: ""})

              }else if(e.key!='Shift' && e.key!='LeftAlt'){ //if keypress is not the CHORDKEY while scanstring is empty and is not SHIFT OR ALT

                // continue building scanstring
                if(!this.scanString || this.scanString === "" || this.scanString === undefined || this.scanString === null) // if this is excluded, the barcode will always have undefined as the first character
                  this.scanString = e.key
                else this.scanString += e.key

              }
            }
          }
        }
      },
      async openProductDialog(id){
        try {
          this.productDialog.isOpen = true
          this.productDialog.isLoading = true 
          this.productDialog.data = await this.lookupProduct(id)
          if(!this.productDialog.data) throw "External Product Not In VIMS DB."
          this.productDialog.isLoading = false
          this.resetScanBus() 
        } catch (error) {
          console.log(error)
          this.snack(error)
          this.closeProductDialog()
        }
      },
      getGreeting(){
        if(this.getUser != null && this.getUser != undefined){
          let d = new Date().getHours()
          let h = ''
          if(d >= 0 && d < 11) h = 'Morning'
          if(d >= 11 && d < 14) h = 'Day'
          if(d >= 14 && d < 17) h = 'Afternoon'
          if(d >= 17 && d < 19) h = 'Evening'
          if(d >= 19 && d <= 23) h = 'Night'
          return 'Good ' + h + ', ' + this.getUser.firstName+"!"
        }
      },
      clearScanString(){
        this.scanString = ''
        console.log("🔁 Scan String Cleared")
      },
      clearScanStringTimeout(){
        this.timeout = setTimeout(this.clearScanString,500)
      },
      cancelClearScanStringTimeout(){
        clearTimeout(this.timeout)
      },
      async globalHandler(){
        try {
          console.log("Global Handler Fired")

          let type = scanSystem.validateType(this.scanBus.data)
          console.log(type)
          let s = this.scanBus.data.replace(/\\\/\/\\=/,'')
          let pId = (s.replace(`${type}-`, ''));
          let p, cn;
          switch(type){
            case 'INV':
              console.log("Invoice Scanned: ", s)
              p = `/orders/view/${s.split('-')[1]}`
              if(this.$route.path!==p)
                await this.$router.push({path: p})
              this.scanInProgress = false
              break
            case 'VSID':
              // this.setScanBus({username: this.lookupUsername(pId.split("-")[0]), uId: pId.split("-")[0]})
              // console.log(this.lookupUsername(pId.split("-")[0]))
              p = '/users/view/'+pId.split("-")[0]
              if(this.$route.path!==p)
                await this.$router.push({path: p})
              this.scanInProgress = false
              break
            case 'QT':
              console.log("Quotation Scanned: ", s)
              p = `/orders/view/${s.split('-')[1]}`
              if(this.$route.path!==p)
                await this.$router.push({path: p})
              this.scanInProgress = false
              break
            case 'JT':
              console.log("Job Ticket Scanned: ", s)
              p = `/orders/view/${s.split('-')[1]}`
              if(this.$route.path!==p)
                await this.$router.push({path: p})
              this.scanInProgress = false
              break
            case 'DN':
              console.log("Delivery Note Scanned: ", s)
              p = `/deliveries/view/${s.split('-')[1]}`
              if(this.$route.path!==p)
                await this.$router.push({path: p})
              this.scanInProgress = false
              break
            case 'PL':
              console.log("Product Scanned: ", s)
              p = `/products/view/${s.split('-')[1]}`
              if(this.$route.path!==p)
                await this.$router.push({path: p})
              this.scanInProgress = false
              break
            case 'CN':
              console.log("Credit Note Scanned: ", s)
              cn = await axios.get(`${this.getEndpoint}/api/creditNotes/${s.split('-',)[1]}`)
              if(cn.data.error) throw cn.data.error
              p = `/customers/view/${cn.data.data.customerId}?cn=${cn.data.data.id}`
              if(this.$route.path!==p)
                await this.$router.push({path: p})
              this.scanInProgress = false
              break
            case 'SKU':
              console.log("External Product Scanned: ", s)
              await this.openProductDialog(s)
              // p = `/products/view/${prod.id}`
              // if(this.$route.path!==p)
              //   await this.$router.push({path: p})
              // this.scanInProgress = false
              break
          }
        } catch (error) {
          console.log(error)
          this.scanInProgress = false
          this.snack(error)
        }
      },
      endShortcut(){
        this.clearScanString()
        this.chordingDialog.chord = []
        this.chordingDialog.isOpen = false
        this.resetScanBus()
      },
      async handleChord(code){
        try {
          this.search.results = []
          let key = code.key.toLowerCase()
          this.chordingDialog.chord.push(key)
          if(this.chordingDialog.chord[this.chordingDialog.chord.length-1]==='x'){
            this.chordingDialog.chord = []
            this.search.results= []
          }
          if(this.chordingDialog.chord[this.chordingDialog.chord.length-1]==='backspace'){
            this.chordingDialog.chord.pop()
            this.chordingDialog.chord.pop()
          }
          let str = this.chordingDialog.chord.join().replace(",","")
          if(str =='on' || str =='n' ){
            console.log("Create New Order")
            this.attemptCreateInvoice()
            this.endShortcut()         
          }
          if(str ==='ol'){
            console.log("List Orders")
            await this.$router.push({path: `/orders`})
            this.endShortcut()              
          }
          if(str[0]=='o'&&str[1]=='v'&&str.length>2&&this.chordingDialog.chord[this.chordingDialog.chord.length-1] == 'enter'){
            let orderId = this.chordingDialog.chord.slice(2,this.chordingDialog.chord.length-1)
            orderId = orderId.join("").replace(/,/g,"")
            console.log(orderId)
            await this.$router.push({path: `/orders/view/${orderId}`})
            this.endShortcut()
          }
          if(str ==='h'){
            await this.$router.push({path: `/`})
            this.endShortcut()              
          }
          if(str[0]=='s'&&str[1]=='p'&&str.length>2){
            let prod = this.chordingDialog.chord.slice(2)
            prod = prod.join("").replace(/,/g,"")
            this.search.results = []
            this.search.type = 'Products'
            this.search.value = prod
            await this.querySearch()
          }
          if(str[0]=='s'&&str[1]=='c'&&str.length>2){
            let prod = this.chordingDialog.chord.slice(2)
            prod = prod.join("").replace(/,/g,"")
            this.search.results = []
            this.search.type = 'Customers'
            this.search.value = prod
            await this.querySearch()
          }
        } catch (error) {
          console.log(error)
          this.snack(error)
        }
      },
      async attemptCreateInvoice(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/orders`, {createdBy: this.getId})
          if(res.data.error) throw res.data.error
          console.log(res.data)
          await this.$router.push({path: `/orders/view/${res.data.data.id}`})
        } catch (error) {
          console.log(error)
        }
      },
      async updateBranch(data){
        try{
          this.branchLoader = true;
          let res = await axios.put(`${this.getEndpoint}/api/users/updateMainBranch/${this.getId}`, {branch: data})
          if(res.data.error) throw res.data.error

          await this.setUser(res.data.data)
          this.setManaged(!!this.getBranchData(this.getBranch)?.metadata.isManaged)
          this.snack("Location Updated");
          if(this.$route.path!=="/") await this.$router.push({path: `/`})

        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this.branchLoader = false;
        }
      },
      async lookupProduct(s){
        try {
          let res = await axios.get(`${this.getEndpoint}/api/products/bySKU/${s}`)
          if(res.data.error) throw res.data.error
          if(!res.data.data) throw 'External barcode is not in VIMS DB.'
          return res.data.data
        } catch (error) {
          if(error.customError){
            console.error(error)
            this.snack('External barcode is not in VIMS DB.')
          }
          else this.snack(error)
        }
      },
      snack(text, color=""){
        if(!text.includes('NavigationDuplicated')){
          this.snackObj.text = text;
          this.snackObj.state = true;
          this.snackObj.color = color;
        }
      },
      openSearchDialog(){
        this.search.dialog=true;
      },
      clearSearch(){
        this.search.value = "";
        this.search.results = [];
      },
      async goToProduct(){
        try {
          await this.$router.push({path: `/products/view/${this.productDialog.data.id}`})
          this.closeProductDialog()
        } catch (error) {
          console.log(error)
        }
      },
      async querySearch(){
        try{
          this.search.loading = true;
          this.search.results = [];

          if(!this.search.value) return;

          let searchData = {
            val: this.search.value
          }

          let uriFields = Object.keys(searchData).map(x => {
            return x + "=" + (searchData[x]!==undefined?encodeURIComponent(searchData[x]):'')
          }).join("&");

          let res;
          let arr = [];
          switch (this.search.type) {
            case "Customers":
              res = await axios.get(`${this.getEndpoint}/api/customers/search?${uriFields}`);
              if(res.data.error) throw res.data.error;
              arr = res.data.data;
              break;
            case "Products":
            default:
              res = await axios.get(`${this.getEndpoint}/api/products/searchByAllVariationsNoLimit?${uriFields}`);
              if(res.data.error) throw res.data.error
              arr = res.data.data;
              console.log(arr.map(x => `${x.id}: ${x.nameScore}`));
          }

          this.search.results = arr;
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this.search.loading = false;
        }
      }
    }
  }
</script>